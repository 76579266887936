<template>
  <div>
    <!-- <div v-if="isShow1"></div> -->
    <!-- <van-skeleton v-if="isShow1" :row="3" /> -->
    <!-- <van-pull-refresh v-model="loading" @refresh="onRefresh" v-else> -->
    <div>
      <div class="top">
        <div class="ab">日分值查询</div>
      </div>
      <div style="position: fixed; top: 0; z-index: 999">
        <div style="position: relative">
          <div>
            <img style="width: 100%" src="@/static/img_year_bg.png" alt="" />
          </div>
          <div class="mon">
            <van-cell @click="showPopup" :style="{ width: '100%', height: '50%' }"><span class="time"
                style="color: #295cc9">{{
              currentDate1 ? currentDate1 : "2023年10月1日"
            }}</span>
              <van-icon name="arrow-down" color="#295CC9" style="margin-left: 0.1rem" /></van-cell>
          </div>

        </div>
        <van-button @click="getData" type="primary" style="position: absolute; right: 40px;bottom: 0.5rem;" plain
          hairline size="small">查询</van-button>
      </div>
      <div class="box" v-if="data.length > 0">
        <div class="count" v-for="item in data" :key="item.id">
          <div class="wid_92">
            <div class="flx">
              <div class="flx6">
                <div class="img">
                  <img src="@/static/tx.png" alt="" />
                </div>
                <div>
                  <div class="flx6">
                    <div class="name">{{ item.customerName }}</div>
                    <div class="team flx8">{{ item.groupName }}</div>
                  </div>
                  <div class="button">{{ item.station }}</div>
                </div>
              </div>
              <div>
                <div class="zhi">
                  {{
              userInfo.scoring != 1
                ? item.realscore.toFixed(2)
                : item.realscore.toFixed(0)
            }}
                </div>
                <div class="score">总分</div>
              </div>
            </div>
            <div class="content">
              <div>
                <div class="flx">
                  <div class="content_left">班次</div>
                  <div class="content_right">{{ item.flight }}</div>
                </div>
                <div class="flx">
                  <div class="content_left">考勤</div>
                  <div class="content_right">{{ item.conditionName }}</div>
                </div>
                <div class="flx">
                  <div class="content_left">基分</div>
                  <div class="content_right">
                    {{
              userInfo.scoring != 1
                ? item.score.toFixed(2)
                : item.score.toFixed(0)
            }}
                  </div>
                </div>
                <div class="flx">
                  <div class="content_left">其他奖扣</div>
                  <div class="content_right" v-if="item.otheraward">
                    {{
              userInfo.scoring != 1
                ? item.otheraward.toFixed(2)
                : item.otheraward.toFixed(0)
            }}
                  </div>
                  <div class="content_right" v-else>--</div>
                </div>
                <div class="flx">
                  <div class="content_left">6S考评（一级）</div>
                  <div class="content_right" v-if="item.patrolOneScore">
                    {{
              userInfo.scoring != 1
                ? item.patrolOneScore.toFixed(2)
                : item.patrolOneScore.toFixed(0)
            }}
                  </div>
                  <div class="content_right" v-else>--</div>
                </div>
                <div class="flx">
                  <div class="content_left">6S考评（二级）</div>
                  <div class="content_right" v-if="item.patrolTwoScore">
                    {{
              userInfo.scoring != 1
                ? item.patrolTwoScore.toFixed(2)
                : item.patrolTwoScore.toFixed(0)
            }}
                  </div>
                  <div class="content_right" v-else>--</div>
                </div>
                <div class="flx">
                  <div class="content_left">6S考评（三级）</div>
                  <div class="content_right" v-if="item.patrolThreeScore">
                    {{
              userInfo.scoring != 1
                ? item.patrolThreeScore.toFixed(2)
                : item.patrolThreeScore.toFixed(0)
            }}
                  </div>
                  <div class="content_right" v-else>--</div>
                </div>
                <div v-if="Option?.limit_patrol_mode?.status == 1">
                  <div class="flx">
                    <div class="content_left">精益基分</div>
                    <div class="content_right">
                      {{
              userInfo.scoring != 1
                ? item.jyscore.toFixed(2)
                : item.jyscore.toFixed(0)
            }}
                    </div>
                  </div>
                  <div class="flx">
                    <div class="content_left">精益细则分</div>
                    <div class="content_right">
                      {{
                userInfo.scoring != 1
                  ? item.jyscoreExam.toFixed(2)
                      : item.jyscoreExam.toFixed(0)
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <van-empty class="box" v-else description="暂无数据" />
      <!-- </van-pull-refresh> -->
    </div>
    <van-popup v-model:show="show" position="bottom">
      <van-datetime-picker v-model="currentDate" type="date" title="选择年月日" value="{{currentDate}}" @confirm="confirm"
        @cancel="cancel" />
    </van-popup>
  </div>
</template>
<script>
import {
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from "vue";

// 全页面背景色配置
import { useRoute, useRouter } from "vue-router";
import { format } from "@/utils/index.js";
export default {
  setup() {
    const router = useRouter();
    const useRouteone = useRoute();
    const { proxy } = getCurrentInstance();
    const reactiveParams = reactive({
      currentDate: format(new Date(), "yyyy-MM-dd"),
      currentDate1: format(new Date(), "yyyy年MM月dd日"),
      isShow: false,
      show: false,
      data: [],
      currentDate: new Date(),
      isShow1: true,
      loading: false,
      userInfo: {},
      Option: JSON.parse(localStorage.getItem("Option"))
        ? JSON.parse(localStorage.getItem("Option"))
        : JSON.parse(sessionStorage.getItem("Option")),
    });
    const onRefresh = () => {
      reactiveParams.loading = true;
      getData();
    };
    const getData = async () => {
      let userInfo = useRouteone.query.userInfo
        ? JSON.parse(useRouteone.query.userInfo)
        : sessionStorage.getItem("userInfo")
          ? JSON.parse(sessionStorage.getItem("userInfo"))
          : JSON.parse(localStorage.getItem("userInfo"));
      console.log(userInfo, "shuj ");
      reactiveParams.userInfo = userInfo;
      console.log(reactiveParams.currentDate);
      let pamas = {
        // companyId: userInfo.companyId,
        // customerGroupId: userInfo.groupId,
        workdate: format(reactiveParams.currentDate, "yyyy-MM-dd"),
        customerId: userInfo.id,
      };
      // let pamas = {
      //   companyId: "1734752397643182081",
      //   customerGroupId: "1734752553532878849",
      //   workdate: "2023-12-20",
      //   customerId: "1734754880306245634",
      // };
      reactiveParams.data = [];
      proxy.$Api.get(proxy.$scor.newlaTotalDays, pamas).then((res) => {
        console.log(res, "数据");
        if (res.data.code == 200) {
          reactiveParams.loading = false;

          reactiveParams.data = res.data.data;
          reactiveParams.isShow1 = false;
        } else {
          reactiveParams.isShow1 = false;
        }
      });
      // let list1 = await proxy.$Api.get(proxy.$scor.laTotalDays, pamas);
      // console.log(list);
    };

    const showPopup = () => {
      reactiveParams.show = true;
    };

    const confirm = async (e) => {
      reactiveParams.currentDate1 = format(e, "yyyy年MM月dd日");
      reactiveParams.currentDate = format(e, "yyyy-MM-dd");
      console.log(reactiveParams.currentDate);
      await cancel();
      await getData();
    };

    const cancel = () => {
      reactiveParams.show = false;
    };
    const getIshow = () => {
      reactiveParams.isShow = reactiveParams.isShow ? false : true;
    };

    onMounted(() => {
      getData();
    });
    // getData();

    // onBeforeMount(() => {
    //   getData();
    // });
    // 开始触发事件
    return {
      ...toRefs(reactiveParams),
      router,
      useRouteone,
      showPopup,
      confirm,
      cancel,
      getIshow,
      getData,
      onRefresh,
    };
  },
};
</script>
<style lang="scss" scoped>
.top {
  // height: 3.4rem;
  background: #295cc9;
  position: relative;
  // display: flex;
  // align-items: end;
  // vertical-align: bottom;
}

.ab {
  padding-left: 0.4rem;
  font-size: 0.32rem;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  bottom: 0;
}

.img {
  width: 0.96rem;
  height: 0.96rem;
}

.img img {
  width: 100%;
  height: 100%;
}

.time {
  font-size: 0.3rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #1a1a1a;
}

.name {
  font-size: 0.36rem;
  margin-left: 0.24rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #1a1a1a;
}

.team {
  font-size: 0.28rem;
  margin-left: 0.16rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #4d4d4d;
}

.button {
  // width: 1.2rem;
  height: 0.32rem;
  background: #ffefef;
  border-radius: 0.16rem;
  font-size: 0.2rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #cc0000;
  text-align: center;
  line-height: 0.32rem;
  margin: 0.2rem 0 0 0.24rem;
}

.zhi {
  font-size: 0.44rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #cc0000;
  line-height: 1;
}

.score {
  font-size: 0.2rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #808080;
  margin-top: 0.24rem;
  text-align: center;
}

.price {
  font-size: 0.3rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #cc0000;
}

.content {
  margin-top: 0.14rem;
}

.content div .flx {
  margin-top: 0.3rem;
}

.content div .flx:last-child {
  padding-bottom: 0.3rem;
}

.content_left {
  font-size: 0.28rem;
  font-weight: 400;
  color: #8c8c8c;
}

.content_right {
  font-size: 0.28rem;
  font-weight: 400;
  color: #1a1a1a;
}

.van-cell {
  background: transparent !important;
}

.mon {
  position: absolute;
  bottom: 0.32rem;
  left: 0.64rem;
}

.count {
  width: 6.8rem;
  min-height: 3rem;
  margin: 0.2rem auto;
  background: white;
  border-radius: 0.1rem;
  font-size: 0.28rem;
}

.wid_92 {
  width: 92%;
  margin: 0 auto;
  padding-top: 0.28rem;
}

.bor {
  margin-top: 0.26rem;
  width: 100%;
  border-bottom: 0.02rem solid #f2f2f2;
}
</style>
